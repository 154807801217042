<div
  *ngIf="segmentGroups && withoutSegmentGroupsProperties"
  [formGroup]="formGroup"
>
  <div
    *ngIf="
      withoutSegmentGroupsProperties &&
      withoutSegmentGroupsProperties.length > 0
    "
    class="mt-4"
  >
    <ng-container *ngFor="let item of withoutSegmentGroupsProperties">
      <ng-container *ngIf="item.type === 'full_address'">
        <app-model-full-address-field
          [property]="item"
          [formControlName]="item.fieldName"
        ></app-model-full-address-field>
      </ng-container>
      <ng-container *ngIf="item.type !== 'full_address'">
        <app-model-field
          [formControlName]="item.fieldName"
          [parentId]="item.id"
          [submitted]="submitted"
          [modelName]="modelName"
        ></app-model-field>
      </ng-container>
    </ng-container>
  </div>
  <accordion
    class="model-accordion"
    *ngIf="segmentGroups && segmentGroups.length > 0"
  >
    <accordion-group
      *ngFor="let group of segmentGroups; let i = index"
      [(isOpen)]="accordionOpened[i]"
    >
      <div class="accordion-header" accordion-heading>
        <h4 class="float-left">{{ group.name | translate }}</h4>
        <div class="float-right">
          <i class="material-icons">{{
            accordionOpened[i] ? "expand_less" : "expand_more"
          }}</i>
        </div>
      </div>
      <div>
        <ng-container *ngFor="let item of group.properties">
          <ng-container *ngIf="item.type === 'full_address'">
            <app-model-full-address-field
              [property]="item"
              [formControlName]="item.fieldName"
              [accordionOpened]="accordionOpened[i]"
            ></app-model-full-address-field>
          </ng-container>
          <ng-container *ngIf="item.type !== 'full_address'">
            <app-model-field
              [formControlName]="item.fieldName"
              [parentId]="item.id"
              [submitted]="submitted"
              [modelName]="modelName"
            ></app-model-field>
          </ng-container>
        </ng-container>
      </div>
    </accordion-group>
  </accordion>
</div>
<div class="mt-3 text-right bottom-save-button">
  <button class="btn btn-primary" type="button" (click)="save()" translate>
    Save
  </button>
</div>
