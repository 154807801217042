import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isEqual, last, isUndefined } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skipWhile,
  takeUntil,
  tap,
  withLatestFrom,
  map,
} from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { PRODUCT_MODEL_NAME } from '../../../../segments/models/segment';
import { SegmentGroup } from '../../../../segments/models/segment-group';
import { SegmentProperty } from '../../../../segments/models/segment-property';
import { getSegmentGroups } from '../../../../segments/store/selectors/segment-groups.selector';
import { getSegmentPropertiesByModel } from '../../../../segments/store/selectors/segment-properties.selector';

export interface ModelSegmentProperty extends SegmentProperty {
  fieldName?: string;
}

@Component({
  selector: 'app-model-form',
  templateUrl: './model-form.component.html',
  styleUrls: ['./model-form.component.scss'],
})
export class ModelFormComponent implements OnInit, OnDestroy {
  @Input() modelName = PRODUCT_MODEL_NAME;
  @Input() isEdit = true;
  @Input() submitted = false;

  @Input() set modelData(value) {
    if (value) {
      this._modelData = value;
      console.log('[Model] set model data', value);
      if (!this.segmentPropertiesSub) {
        this.segmentPropertiesSub = this.store
          .pipe(
            select(getSegmentPropertiesByModel(this.modelName)),
            filter((props) => props && props.length),
            distinctUntilChanged((p, q) => isEqual(p, q)),
            map((properties) => properties.filter((p) => p.is_readable)),
            withLatestFrom(this.store.pipe(select(getSegmentGroups)))
          )
          .subscribe(([data, groups]: [SegmentProperty[], SegmentGroup[]]) => {
            this.allSegmentProperties = data;
            const dataProperty = data.find((it) => it.path === 'data');
            const currentProperties = data
              .filter(
                (it) =>
                  ((dataProperty && it.parent === dataProperty.id) ||
                    it.parent === null) &&
                  it.path !== 'data'
              )
              .map(
                (it: SegmentProperty): ModelSegmentProperty => ({
                  ...it,
                  fieldName: this.getPropertyFieldName(it),
                })
              );

            if (!isEqual(currentProperties, this.currentProperties)) {
              this.currentProperties = this.filterProperties(currentProperties);

              this.segmentGroups = groups
                .map((group) => {
                  const properties = this.currentProperties.filter(
                    (it) => it.group === group.id
                  );
                  const sortedProperties =
                    this.reorderPropertiesByType(properties);
                  return { name: group.name, properties: sortedProperties };
                }, {})
                .filter((it) => it.properties.length !== 0);

              console.log(
                '[Model] props and group',
                this.currentProperties,
                this.segmentGroups
              );

              this.accordionOpened = !this.accordionOpened.length
                ? this.segmentGroups.map(() => false)
                : this.accordionOpened;

              this.withoutSegmentGroupsProperties =
                this.reorderPropertiesByType(
                  this.currentProperties.filter((it) => !it.group)
                );

              this.formGroup = this.fb.group(
                this.currentProperties.reduce(
                  (acc, prop: ModelSegmentProperty) => {
                    return {
                      ...acc,
                      [prop.fieldName]: new FormControl(),
                    };
                  },
                  {}
                )
              );

              console.log('[Model] set formGroup', this.formGroup);
              this.updateForm(value);
            }
          });
      } else {
        console.log('[Model] update data', value);
        this.updateForm(value);
      }
    }
  }

  @Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

  _modelData: any;

  formGroup: FormGroup = new FormGroup({});
  subscriptions = new Subject();
  accordionOpened: boolean[] = [];
  segmentPropertiesSub: Subscription;
  allSegmentProperties: SegmentProperty[];
  currentProperties: ModelSegmentProperty[];
  formSubscription: Subscription;
  segmentGroups: { name: string; properties: ModelSegmentProperty[] }[];
  withoutSegmentGroupsProperties: ModelSegmentProperty[];

  isChanging: boolean;
  isFormChanged = false;
  isReverted = false;
  wrappedActionInProcess = false;
  skipChanges = false;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit() {}

  reorderPropertiesByType(properties: SegmentProperty[]): SegmentProperty[] {
    return [
      ...properties.filter((it) => this.isScalar(it)),
      ...properties.filter((it) => this.isObject(it)),
      ...properties.filter((it) => this.isArray(it)),
    ];
  }

  // subscribeToFormChanges() {
  //   this.formSubscription = this.formGroup.valueChanges
  //     .pipe(
  //       takeUntil(this.subscriptions),
  //       distinctUntilChanged((p, q) => isEqual(p, q)),
  //       skipWhile(() => this.skipChanges),
  //       tap(() => {
  //         this.submitted = true;
  //       }),
  //       filter(() => this.isEdit && this.formGroup.valid && !this.isReverted),
  //       debounceTime(100),
  //       tap(() => {
  //         this.isFormChanged = true;
  //         this.isChanging = true;
  //       })
  //     )
  //     .subscribe((data) => {
  //     });
  // }

  save() {
    if (this.isEdit && this.formGroup.valid) {
      this.isFormChanged = false;
      this.isChanging = false;

      let fixedObject = this.fixedObject(this.formGroup.value);
      fixedObject = this.fromDataToModel(fixedObject);

      this.dataChanged.emit(fixedObject );
    }
  }

  // TODO: Тут рекурсивно обрабатывается обьект, чтобы исправить неправильные
  fixedObject(obj: any): any {
    const ctx = this;

    function traverse(item, isInManaged = false) {
      if (Array.isArray(item)) {
        return item
          .filter(element => {
            if (typeof element === 'object' && element !== null) {
              return !(element.ext_id === null && element.name === null);
            }
            return true;
          })
          .map(el => traverse(el, isInManaged));
      } else if (typeof item === 'object' && item !== null) {
        if (item.ext_id && typeof item.ext_id === 'object' && item.ext_id.name && item.ext_id.ext_id) {
          return {
            name: item.ext_id.name,
            ext_id: item.ext_id.ext_id
          };
        } else if (item.name && typeof item.name === 'object' && item.name.name && item.name.ext_id) {
          return {
            name: item.name.name,
            ext_id: item.name.ext_id
          };
        } else {
          const fixedObject = {};
          for (const key in item) {
            if (key === 'managed') {
              const managedObj = {};
              for (const managedKey in item[key]) {
                if (typeof item[key][managedKey] === 'string') {
                  managedObj[managedKey] =
                    ctx.findExtId(ctx.allSegmentProperties, item[key][managedKey]);
                } else {
                  managedObj[managedKey] = traverse(item[key][managedKey]);
                }
              }
              fixedObject[key] = managedObj;
            } else if (item[key] !== null) {
              fixedObject[key] = traverse(item[key]);
            }
          }
          return fixedObject;
        }
      }
      return item;
    }

    return traverse(obj);
  }

  fromDataToModel(data: any): any {
    const newModelData = this.currentProperties.reduce(
      (acc, prop: ModelSegmentProperty) => {
        let value = data[prop.fieldName];
        if (
          prop.widget === 'remote_model' &&
          prop.type === 'uuid' &&
          data[prop.fieldName]
        ) {
          if (prop.is_many) {
            value = data[prop.fieldName].map((d) => d.id || d);
          } else {
            value = data[prop.fieldName].id || data[prop.fieldName];
          }
        }
        if (prop.path.startsWith('data.')) {
          return {
            ...acc,
            data: {
              ...acc.data,
              [prop.fieldName]: !isUndefined(value) ? value : undefined,
            },
          };
        } else {
          return {
            ...acc,
            [prop.fieldName]: !isUndefined(value) ? value : undefined,
          };
        }
      },
      {
        // data: {},
      } as any
    );

    if (data.full_address) {
      newModelData['address'] = data.full_address.address;
      newModelData['latitude'] = Number(data.full_address.latitude);
      newModelData['longitude'] = Number(data.full_address.longitude);
    }

    return newModelData;
  }

  // TODO: Поиск товара по проерти
  findExtId(dataArray: any, extId: string) {
    for (const item of dataArray) {
      if (item.ext_id === extId) {
        return item;
      }
      if (item.property_values && Array.isArray(item.property_values)) {
        const result = this.findExtId(item.property_values, extId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  wrapActionWithoutChanges(action: Function) {
    if (this.wrappedActionInProcess) {
      action();
      return;
    }
    this.wrappedActionInProcess = true;
    this.skipChanges = true;

    action();
    setTimeout(() => {
      this.skipChanges = false;
      this.wrappedActionInProcess = false;
    }, 150);
  }

  getPropertyFieldName(property: SegmentProperty) {
    return last(property.path.split('.'));
  }

  hasChildren(id: string) {
    return (
      this.allSegmentProperties.filter((it) => it.parent === id).length > 0
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    if (!this.segmentPropertiesSub) {
      this.segmentPropertiesSub.unsubscribe();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  isObject(segmentProperty: SegmentProperty) {
    return (
      this.hasChildren(segmentProperty.id) &&
      !segmentProperty.is_many &&
      segmentProperty.widget !== 'enum'
    );
  }

  isScalar(segmentProperty: SegmentProperty) {
    return (
      !this.hasChildren(segmentProperty.id) || segmentProperty.widget === 'enum'
    );
  }

  isArray(segmentProperty: SegmentProperty) {
    return this.hasChildren(segmentProperty.id) && segmentProperty.is_many;
  }

  updateForm(value: any) {
    this.wrapActionWithoutChanges(() => {
      if (!this.isChanging) {
        this.formGroup.patchValue(
          {
            ...value,
            ...value.data,
            full_address: {
              address: value.address,
              longitude: value.longitude,
              latitude: value.latitude,
            },
          },
          { emitEvent: false }
        );
        this.isReverted = false;

        if (this.formSubscription) {
          this.formSubscription.unsubscribe();
        }
        // this.subscribeToFormChanges();
      }
    });
  }

  // @TODO: какой-то лютый костыль — нужно исправить
  filterProperties(properties: ModelSegmentProperty[]): ModelSegmentProperty[] {
    const pointProp = properties.find((prop) => prop.widget === 'address');
    if (pointProp) {
      properties = properties.filter(
        (prop) => prop.widget !== 'address' && prop.widget !== 'point'
      );
      properties.push({
        fieldName: 'full_address',
        id: null,
        group: pointProp.group,
        is_editable: pointProp.is_editable,
        is_enum: false,
        is_filter: false,
        is_readable: false,
        is_many: false,
        is_segment_filter: false,
        is_required: pointProp.is_required,
        name: 'full address',
        path: 'full_address',
        widget: 'full_address',
        model: pointProp.model,
        parent: null,
        type: 'full_address',
      });
      return properties;
    } else {
      return properties;
    }
  }
}
